import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface loadingState {
  loading: boolean;
  animation?: 'lottie'|'image';
  path?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
  private isLoading: loadingState = {loading: true};
  loadingStatus: Subject<loadingState> = new Subject();

  get loading() {
    return this.isLoading;
  }

  set loading(loadState) {
    this.isLoading = loadState;
    this.loadingStatus.next(loadState);
  }

  startLoading(type?: 'lottie'|'image', path?: string) {
    this.loading = {
      loading: true,
      animation: type === undefined ? 'image' : 'lottie',
      path,
    };
  }

  stopLoading() {
    this.loading = {
      loading: false,
    };
  }
}
