import { Permission } from './permission.models';

export class PermissionGroup {
    constructor(
        public id: string|null,
        public name: string,
        public permissions: Permission[],
    ) {
    }

    json(save: boolean = false): any {
        return {
            name: this.name,
            permissions: save ? this.permissions.map(p => p.json()) : this.permissions,
        };
    }

    hasPermission(permissionID: string): boolean {
        return this.permissions.some(p => p.id === permissionID);
    }

    permissionsByController(controllerID: string) {
        const perms = this.permissions.filter( p => p.controller === controllerID);
        return perms;
    }
}
