
import { ApiService } from './../api/api.service';
import { apiConfig } from './../../core/backend/api.config';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { functions } from 'firebase';
import { loginErrors } from 'src/app/core/labels/login.labels';
import { User } from 'src/app/models/core/auth/user.models';
import { PermissionGroup } from 'src/app/models/permissions/permissionGroup.models';
import { AuthLogService } from './authLog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User|null = null;
  public $auth: BehaviorSubject<{user: User, data?: any}> =  null;

  constructor(
      private afAuth: AngularFireAuth,
      private api: ApiService,
      private logService: AuthLogService,
    ) {
    this.$auth = new BehaviorSubject(null);
    this.afAuth.authState.subscribe( async state => {
      if (state) {
        let provider = '';
        if (state.providerId !== 'custom') {
          this.user = await this.checkById(state.uid);
          if (state.metadata.lastSignInTime !== undefined) {
            this.user.lastLogin = new Date(state.metadata.lastSignInTime);
          }
          provider = 'id';
        } else {
          this.user = await this.checkByToken(state.uid);
          provider = 'token';
        }
        this.$auth.next({user: this.user, data: {type: provider}});
      } else {
        this.$auth.next({user: null});
      }
    });
  }

  async __build(data): Promise<User|null> {
    const group = new PermissionGroup(data.group.id, data.group.name, data.group.permissions);
    const user = new User(data.id, data.name, data.email, data.scope, group, data.school, data.origin, data.defaultRoute);
    return user;
  }

  async save(user: User): Promise<User> {
    let response: any = {};
    if (user.id === null) {
      // Create
      response = await this.api.post(apiConfig.user.save, user.json(true));
    } else {
      // Update
      response = await this.api.put(`${apiConfig.user.update}/${user.id}`, user.json(true));
    }
    if (!response.error) {
      user.id = response.data.id;
    }
    return user;
  }

  async loginByEmail(email: string, password: string): Promise<{success: boolean, error?: string}> {
    console.log('LOGIN BY EMAIL');
    const login: {success: boolean, error?: string} = {success: false};
    try {
      const intent = await this.afAuth.signInWithEmailAndPassword(email, password);
      login.success = intent.user !== null;
      if (intent.user !== null) {
        try {
          await this.logService.save('LOGIN', 'LOGIN', '');
        } catch (error) {
          console.error(`[Auth Service] Logs: ${error}`);
        }
      }
    } catch (error) {
      login.error = loginErrors[error.code];
    }
    return login;
  }

  async logout(): Promise<{success: boolean, error?: string}> {
    const logout: {success: boolean, error?: string} = {success: false};
    try {
      await this.logService.save('LOGIN', 'LOGOUT', '');
      const intent = await this.afAuth.signOut();
      logout.success = true;
    } catch (error) {
      logout.error = loginErrors[error.code];
    }
    return logout;
  }

  async loginByToken(token: string): Promise<{success: boolean, error?: string}> {
    const login: {success: boolean, error?: string} = {success: false};
    const intent = await this.afAuth.signInWithCustomToken(token);
    if (intent.user !== null) {
      login.success = true;
    } else {
      login.error = 'Login fallido';
    }
    return login;
  }

  async resetPassword(instance: User) {
    const intent = await this.afAuth.sendPasswordResetEmail(instance.email);
    return true;
  }

  async checkById(id: string): Promise<User|null> {
    let user: User|null = null;
    const response = await this.api.get(`${apiConfig.user.byId}/${id}`);
    if (!response.error) {
      user = await this.__build(response.data);
      console.log('USER', user);
    }
    return user;
  }

  async all(): Promise<User[]> {
    const users: User[] = [];
    const response = await this.api.get(`${apiConfig.user.byId}`);
    if (!response.error) {
      for (const user of response.data) {
        users.push(await this.__build(user));
      }
    }
    console.log(users);
    return users;
  }

  async checkByToken(id: string): Promise<User|null> {
    let user = null;
    const response = await functions().httpsCallable(apiConfig.user.byId)({}) as any;
    if (!response.error) {
      user = await this.__build(response.data);
    }
    return user;
  }


  async byName(name: string): Promise<User[]> {
    const instances: User[] = [];
    const response = await this.api.get(`${apiConfig.user.byField}/name/${name.toUpperCase()}`);
    if (!response.error) {
      for (const modelData of response.data) {
        instances.push(await this.__build(modelData));
      }
    }
    return instances;
  }

}
