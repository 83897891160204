import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { LoadingScreenService } from './services/loading-screen/loading-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spepm-fe';
  constructor(
    private router: Router,
    private loadingService: LoadingScreenService,
    private authService: AuthService,
    // private screenSize: ScreensizeService,
    ) {}
    ngOnInit() {
      // Screen sizes
      // this.screenSize.setSizes({width: window.innerWidth, height: window.innerHeight});
      this.loadingService.startLoading('image');
      this.authService.$auth.subscribe(authState => {
        if (authState !== null) {
          if (authState.user !== null) {
            this.router.navigate(['dashboard', authState.user.scope]);
          } else {
            this.router.navigate(['login']);
          }
        } else {
          console.log('Keep loading');
        }
      });
    }

}
