import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { apiConfig } from '../../core/backend/api.config';
import { User } from 'src/app/models/core/auth/user.models';
import { UserLog } from 'src/app/models/core/auth/userLog.models';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthLogService {

  private $listeners: {[id: string]: () => void | null} = {};
  public usersLogs = new BehaviorSubject<{[key: string]: UserLog[]}>({});
  private currentLogs: {[key: string]: UserLog[]} = {};
  constructor(private api: ApiService) { }

  async __build(data: any): Promise<UserLog> {
    const instance = new UserLog(data.userId, data.id, data.module, data.action, data.objectRelated, new Date(data.date.seconds * 1000));
    return instance;
  }

  async save(module: string, action: string, relatedObject?: string): Promise<void> {
    await this.api.post(apiConfig.user.logs, {module, action, relatedObject});
  }

  async listenLogChanges(user: User): Promise<boolean> {
    const MAXLOGS = 10;
    let status = false;
    if (this.$listeners[user.id] === undefined) {
      const fromDate = new Date(`${new Date().toISOString().slice(0, 10)}T03:00:00.000Z`);
      const toDate = new Date(`${new Date().toISOString().slice(0, 10)}T23:59:59.990Z`);
      this.$listeners[user.id] = firebase.firestore()
        .collectionGroup('userLogs')
        .where('userId', '==', user.id)
        .where('date', '>=', fromDate)
        .where('date', '<=', toDate)
        .orderBy('date', 'desc')
        .onSnapshot(async (querySnapshot) => {
            for (const change of querySnapshot.docChanges()) {
              if (change.type === 'added') {
                if (this.currentLogs[user.id] === undefined) {
                  this.currentLogs[user.id] = [];
                }
                // if (this.currentLogs[user.id].length >= MAXLOGS) {
                //   this.currentLogs[user.id].shift();
                // }
                const buildedLog = await this.__build(change.doc.data());
                const actualDate = new Date();
                actualDate.setHours(actualDate.getHours() + 1);
                if (buildedLog.date.getTime() <= actualDate.getTime()) {
                  this.currentLogs[user.id].push(buildedLog);
                }
              }
            }
            if (this.currentLogs[user.id] !== undefined && this.currentLogs[user.id].length > 0) {
              this.usersLogs.next(this.currentLogs);
            }
        });
    } else {
      status = false;
    }
    return status;
  }

  stopListenLogChanges() {
    for (const userid in this.$listeners) {
      if (Object.prototype.hasOwnProperty.call(this.$listeners, userid)) {
        const element = this.$listeners[userid];
        try {
          element();
        } catch (error) {
          console.error(`[Auth Log Service] Stop listening changes in Real Time: ${userid} -> ${error}`);
        }
      }
    }
    this.$listeners = {};
    this.currentLogs = {};
  }


}
