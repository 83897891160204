import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: HttpClient) { }

  async get(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const sub = this.http.get(url).subscribe((data) => {
        resolve(data);
        sub.unsubscribe();
      });
    });
  }

  async delete(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const sub = this.http.delete(url).subscribe((data) => {
        resolve(data);
        sub.unsubscribe();
      });
    });
  }

  async post(url: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const sub = this.http.post(url, data).subscribe((response) => {
        resolve(response);
        sub.unsubscribe();
      });
    });
  }

  async put(url: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const sub = this.http.put(url, data).subscribe((response) => {
        resolve(response);
        sub.unsubscribe();
      });
    });
  }

}
