export interface IUserLog {
    module: 'LOGIN'|'PROCEDURE'|'CLASSIFICATION'|'INSTITUTE'|'ORGANISM'|'PERSON'|'SECTOR'|string,
    action: string;
    objectRelated: string;
}

export class UserLog{
    constructor(
        public userId: string,
        public id: string|null,
        public module: 'LOGIN'|'PROCEDURE'|'CLASSIFICATION'|'INSTITUTE'|'ORGANISM'|'PERSON'|'SECTOR'|'PROCEDUREDETAIL'|string,
        public action: 'ADD'|'EDIT'|'ACCESS'|string,
        public objectRelated: string,
        public date: Date,
    ) {}

    json(save: boolean = false): any {
        return {
            userId : this.userId,
            id : this.id,
            module : this.module,
            action : this.action,
            objectRelated : this.objectRelated,
            date : this.date,
        };
    }

    translateModule(): string {
        let name = this.module;
        switch (this.module) {
            case 'LOGIN':
                name = 'Autenticación';
                break;
            case 'PROCEDURE':
                name = 'Trámites';
                break;
            case 'PROCEDUREDETAIL':
                name = 'Entradas';
                break;
            case 'CLASSIFICATION':
                name = 'Clasificaciones';
                break;
            case 'INSTITUTE':
                name = 'Institutos';
                break;
            case 'ORGANISM':
                name = 'Organismos';
                break;
            case 'PERSON':
                name = 'Personas';
                break;
            case 'SECTOR':
                name = 'Sectores';
                break;
            case 'USERS':
                name = 'Usuarios';
                break;
            default:
                break;
        }
        return name;
    }
    translateAction(): string {
        let name = this.action;
        switch (this.action) {
            case 'ADD':
                name = 'Creación';
                break;
            case 'CREATE':
                name = 'Creación';
                break;
            case 'SECTORPROCEDURE':
                name = 'Mis trámites';
                break;
            case 'UPDATE':
                name = 'Actualización';
                break;
            case 'REPORT':
                name = 'Reportes';
                break;
            case 'LOGIN':
                name = 'Ingreso';
                break;
            case 'LOGOUT':
                name = 'Salida';
                break;
            case 'PASSTO':
                name = 'Pase';
                break;
            case 'REPORT':
                name = 'Reporte';
                break;
            case 'QUICKSEARCH':
                name = 'Búsqueda Rápida';
                break;
            case 'ACCEPT':
                name = 'Aceptación';
                break;
            case 'CLOSE':
                name = 'Cierre';
                break;
            default:
                break;
        }
        return name;
    }

}
