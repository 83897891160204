import { Subscription } from 'rxjs';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { LoadingScreenService } from 'src/app/services/loading-screen/loading-screen.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  loading = false;
  loadingSubscription: Subscription;
  public lottieoptions: AnimationOptions;
  public type: 'image' | 'lottie' = 'image';
  constructor(private loadingScreenService: LoadingScreenService) {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
      this.lottieoptions = { path: value.path};
      this.type = value.animation === undefined ? 'image' : value.animation;
      this.loading = value.loading;
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
