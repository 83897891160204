import { PermissionGroup } from '../../permissions/permissionGroup.models';
import { Permission } from '../../permissions/permission.models';
import { Sector } from '../../sector/sector.models';
import { Subject } from 'rxjs';
import { School } from '../../school/school.models';

export class User {
  public currentSector: Sector;
  public $currentSector: Subject<Sector> = new Subject();
  public lastLogin: Date = new Date();
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public scope: 'external'|'internal',
    public group: PermissionGroup,
    public school: School | null,
    public origin: string,
    public defaultRoute?: string,
    // Only in creation procedure
    public password?: string,
  ) {}

  public setCurrentSector(sector: Sector) {
    if (this.currentSector === undefined || (sector.id !== this.currentSector.id)) {
      this.currentSector = sector;
      this.$currentSector.next(sector);
    }
  }

  public json(save?: boolean): any {

    let school = null;
    if (this.school !== null) {
      if (save) { school = this.school.id; } else { school = this.school.json(); }
    }

    return {
      id: this.id,
      name: this.name,
      email: this.email,
      scope: this.scope,
      group: save ? this.group.id : this.group.json(),
      school,
      origin: this.origin,
      password: this.password === undefined ? '' : this.password,
      defaultRoute: this.defaultRoute === undefined ? '' : this.defaultRoute,
    };
  }


    /**
     * Check permission by Permission instance
     */
    hasPermission(permission: Permission): boolean {
      return this.group.hasPermission(permission.id);
    }

  /**
   * Check permission by Permission String ID in format Controller.Permission ex:
   * ```
   * AuthController.read
   * ```
   */
  hasPermissionID(permissionID: string): boolean {
      return this.group.hasPermission(permissionID);
  }

}
