import { environment } from 'src/environments/environment';
const baseUrl = environment.baseUrl;
export const apiConfig = {
  user: {
    save: `${baseUrl}/auth`,
    update: `${baseUrl}/auth`,
    byId: `${baseUrl}/auth`,
    byField: `${baseUrl}/auth/byField`,
    logs: `${baseUrl}/auth/logs`,
  },
  permissionGroup: {
    save: `${baseUrl}/permissiongroup`,
    update: `${baseUrl}/permissiongroup`,
    all: `${baseUrl}/permissiongroup`,
    globalPermissions: `${baseUrl}/permissiongroup/permissions/global`,
    byField: `${baseUrl}/permissiongroup/byId`,
    byController: `${baseUrl}/permissiongroup/byController`,
  },
  institutes: {
    byId: `${baseUrl}/school`,
    save: `${baseUrl}/school`,
    update: `${baseUrl}/school`,
    byField: `${baseUrl}/school/byField`,
    summary: `${baseUrl}/school/summary/schoolId`,
  },
  person: {
    byId: `${baseUrl}/person`,
    byField: `${baseUrl}/person/byField`,
    save: `${baseUrl}/person`,
    update: `${baseUrl}/person`,
  },
  sector: {
    byId: `${baseUrl}/sector`,
    byField: `${baseUrl}/sector/byField`,
    byUser: `${baseUrl}/sector/byUser`,
    save: `${baseUrl}/sector`,
    update: `${baseUrl}/sector`,
  },
  organism: {
    byId: `${baseUrl}/organism`,
    byField: `${baseUrl}/organism/byField`,
    byUser: `${baseUrl}/organism/byUser`,
    save: `${baseUrl}/organism`,
    update: `${baseUrl}/organism`,
  },
  procedureclassification: {
    byId: `${baseUrl}/procedureclassification`,
    byField: `${baseUrl}/procedureclassification/byField`,
    byUser: `${baseUrl}/procedureclassification/byUser`,
    save: `${baseUrl}/procedureclassification`,
    update: `${baseUrl}/procedureclassification`,
  },
  procedurecategory: {
    byId: `${baseUrl}/procedurecategory`,
    byField: `${baseUrl}/procedurecategory/byField`,
    byUser: `${baseUrl}/procedurecategory/byUser`,
    save: `${baseUrl}/procedurecategory`,
    update: `${baseUrl}/procedurecategory`,
  },
  procedure: {
    byId: `${baseUrl}/procedure`,
    byReportSearch: `${baseUrl}/procedure/byReport/search`,
    byReportSeachMovementsBySector: `${baseUrl}/procedure/byReport/movementsBySector`,
    passTo: `${baseUrl}/procedure`,
    accept: `${baseUrl}/procedure`,
    close: `${baseUrl}/procedure`,
    byField: `${baseUrl}/procedure/byField`,
    byUnreadSector: `${baseUrl}/procedure/byUnreadSector`,
    byActiveSector: `${baseUrl}/procedure/byActiveSector`,
    byUser: `${baseUrl}/procedure/byUser`,
    save: `${baseUrl}/procedure`,
    update: `${baseUrl}/procedure`,
    pullRelated: `${baseUrl}/procedure/{procedureid}/pullRelated`,
  },
  proceduremovement: {
    byId: `${baseUrl}/procedure/{procedureid}/movements`,
    byField: `${baseUrl}/procedure/{procedureid}/movements/byField`,
    byOrigin: `${baseUrl}/procedure/{procedureid}/movements/bySector/origin`,
    byDestination: `${baseUrl}/procedure/{procedureid}/movements/bySector/destination`,
    save: `${baseUrl}/procedure/{procedureid}/movements`,
    update: `${baseUrl}/procedure/{procedureid}/movements`,
  },
  proceduredetail: {
    byId: `${baseUrl}/procedure/{procedureid}/details`,
    byField: `${baseUrl}/procedure/{procedureid}/details/byField`,
    byOrigin: `${baseUrl}/procedure/{procedureid}/details/bySector/origin`,
    byDestination: `${baseUrl}/procedure/{procedureid}/details/bySector/destination`,
    save: `${baseUrl}/procedure/{procedureid}/details`,
    update: `${baseUrl}/procedure/{procedureid}/details`,
  },
  departments: {
    byId: `${baseUrl}/department/`,
    byField: `${baseUrl}/department/byField`,
    save: `${baseUrl}/department/`,
    update: `${baseUrl}/department`,
  },
  localities: {
    byId: `${baseUrl}/locality/{departmentid}`,
    allByDepartment: `${baseUrl}/locality/{departmentid}`,
    save: `${baseUrl}/locality/{departmentid}`,
    saveBulk: `${baseUrl}/locality/{departmentid}/bulk`,
    update: `${baseUrl}/locality/{departmentid}`,
    delete: `${baseUrl}/locality/{departmentid}`,
  },
  levels: {
    byId: `${baseUrl}/level`,
    save: `${baseUrl}/level`,
    update: `${baseUrl}/level`,
    byField: `${baseUrl}/level/byField`,
  },
  resolutions: {
    byId: `${baseUrl}/resolution`,
    save: `${baseUrl}/resolution`,
    update: `${baseUrl}/resolution`,
    byField: `${baseUrl}/resolution/byField`,
  },
  authorities: {
    byId: `${baseUrl}/authority`,
    save: `${baseUrl}/authority`,
    update: `${baseUrl}/authority`,
    byField: `${baseUrl}/authority/byField`,
  },
  supervisions: {
    byId: `${baseUrl}/supervision`,
    save: `${baseUrl}/supervision`,
    update: `${baseUrl}/supervision`,
    byField: `${baseUrl}/supervision/byField`,
    delete: `${baseUrl}/supervision/{supervisionid}`,
    byReportSearch: `${baseUrl}/supervision/byReport/search`,
  },
  functionalPlants: {
    byId: `${baseUrl}/functionalPlant`,
    save: `${baseUrl}/functionalPlant`,
    update: `${baseUrl}/functionalPlant`,
    byField: `${baseUrl}/functionalPlant/byField`,
    delete: `${baseUrl}/functionalPlant/{functionalPlantId}`,
    byReportSearch: `${baseUrl}/functionalPlant/byReport/search`,
  },
  presentations: {
    byId: `${baseUrl}/presentation`,
    save: `${baseUrl}/presentation`,
    update: `${baseUrl}/presentation`,
    byField: `${baseUrl}/presentation/byField`,
    delete: `${baseUrl}/presentation/{presentationId}`,
    byReportSearch: `${baseUrl}/presentation/byReport/search`,
  },
  substitutionIndexes: {
    byId: `${baseUrl}/substitutionIndex`,
    save: `${baseUrl}/substitutionIndex`,
    update: `${baseUrl}/substitutionIndex`,
    byField: `${baseUrl}/substitutionIndex/byField`,
    delete: `${baseUrl}/substitutionIndex/{substitutionIndexId}`,
    byReportSearch: `${baseUrl}/substitutionIndex/byReport/search`,
  },
  modalities: {
    byId: `${baseUrl}/modality`,
    save: `${baseUrl}/modality`,
    update: `${baseUrl}/modality`,
    byField: `${baseUrl}/modality/byField`,
  },
  incompatibilities: {
    byId: `${baseUrl}/incompatibility`,
    save: `${baseUrl}/incompatibility`,
    update: `${baseUrl}/incompatibility`,
    byField: `${baseUrl}/incompatibility/byField`,
    delete: `${baseUrl}/incompatibility/{incompatibilityId}`,
    byReportSearch: `${baseUrl}/incompatibility/byReport/search`,
  },
  decrees: {
    byId: `${baseUrl}/decree`,
    save: `${baseUrl}/decree`,
    update: `${baseUrl}/decree`,
    byField: `${baseUrl}/decree/byField`,
  },
  dispositions: {
    byId: `${baseUrl}/disposition`,
    save: `${baseUrl}/disposition`,
    update: `${baseUrl}/disposition`,
    byField: `${baseUrl}/disposition/byField`,
  },
  providences: {
    byId: `${baseUrl}/providence`,
    save: `${baseUrl}/providence`,
    update: `${baseUrl}/providence`,
    byField: `${baseUrl}/providence/byField`,
  },
};
