import { AngularFireAuth } from '@angular/fire/auth';
import { MaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { firebaseEnvironment } from 'src/environments/environment';
import * as firebase from 'firebase';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/firebase-auth.interceptor';
import { TdMediaService } from '@covalent/core/media';


import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

export function playerFactory() {
  return player;
}
firebase.initializeApp(firebaseEnvironment.config);

@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseEnvironment.config),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    HttpClientModule,
    AngularFireAuth,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    TdMediaService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
